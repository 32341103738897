import React, { useContext, useState, useEffect } from "react";
import styles from "./sidebar.module.css";
import { ProductsContext } from "../../contexts/ProductsContext";
import ProductImage from "../../components/product-image";
import { Link } from "react-router-dom";

const PageItem = ({ page }) => {
  const { PageImages } = useContext(ProductsContext);
  const [pageDetail, setPageDetail] = useState({});
  useEffect(() => {
    const filterPage = PageImages.find((item) => {
      return item.id === page?.node?.id;
    });
    setPageDetail(filterPage);
  }, [PageImages, setPageDetail, page]);
  const {
    pageItem,
    pageContent,
    pageTitleContainer,
    pageName,
    btn,
  } = styles;

  return (
    <div className={pageItem}>
      <Link to={`/about/${page.node.handle}`} style={{textDecoration:'none'}}>
        <div className={pageContent}>
          <ProductImage src={pageDetail?.img} alt={page.node.title} />
          <div className={pageTitleContainer}>
            <p className={pageName}>{page.node.title} </p>
            <button className={btn}>Read Article</button>
          </div>
        </div>

        
      </Link>
    </div>
  );
};

export default PageItem;
