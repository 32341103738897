import React, { useContext, useEffect, useCallback, useState } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "../icons";
import ProductImage from "../../components/product-image";
import CartCount from "../../components/cart-count";
import styles from "./cart-item.module.css";
import { ProductsContext } from "../../contexts/ProductsContext";
import { Link } from "react-router-dom";

const CartItem = ({ product }) => {
  const { title, quantity, id, variant } = product;
  const [type, setProductType] = useState("");
  const {
    title: variantTitle,
    price: variantPrice,
    product: { handle },
  } = product.variant;
  const { updateQuantityInCart, removeLineItemInCart, getCartProduct } =
    useContext(ProductsContext);

  const {
    CartItem,
    productContent,
    productTitleContainer,
    productName,
    productNameWrapper,
    productType,
    productPrice,
    cartCountPosition,
    incrementContainer,
    removeButton,
    productVariant,
  } = styles;

  const fetchProduct = useCallback(async () => {
    const data = await getCartProduct(variant?.product?.id);
    if (data) {
      setProductType(data);
    }
  }, [getCartProduct, variant]);

  useEffect(() => {
    fetchProduct();
    window.scrollTo(2, 2);
  }, [fetchProduct]);
  return (
    <div className={CartItem}>
      <div className={productContent}>
        <ProductImage
          src={product.variant ? product.variant.image.src : product.image.src}
          alt={product.title}
        />
        <Link to={`/product/${handle}`} className={productTitleContainer}>
          <div className={productNameWrapper}>
            <div className={productName}>
              {title}
              <div className={cartCountPosition}>
                <CartCount count={quantity} className={cartCountPosition} />
              </div>
            </div>
          </div>

          <p className={productType}>
            {type}
            <small className={productVariant}>
              {variantTitle !== "Default Title" && `(${variantTitle})`}
            </small>
            <span className={productPrice}>
              <small>$</small>
              {variantPrice}
            </span>
          </p>
        </Link>
        {quantity === 1 && (
          <button
            className={removeButton}
            onClick={() => {
              removeLineItemInCart(product.id);
            }}
          >
            remove
          </button>
        )}
      </div>

      <div className={incrementContainer}>
        <button
          onClick={() => {
            let increaseQuantity = quantity + 1;
            updateQuantityInCart(id, increaseQuantity);
          }}
        >
          <PlusCircleIcon width={"30px"} background={"#6a3016"} />
        </button>

        {product.quantity > 1 && (
          <button
            onClick={() => {
              let decreaseQuantity = quantity - 1;

              updateQuantityInCart(id, decreaseQuantity);
            }}
          >
            <MinusCircleIcon width={"30px"} background={"#6a3016"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CartItem;
