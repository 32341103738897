import React, { useContext } from "react";
import styles from "./cart-modal.module.css";
import Modal from "../modal";
import Button from "../button";
import CartProducts from "../cart-products";
import { FullScreenIcon } from "../icons";
import { ProductsContext } from "../../contexts/ProductsContext";

const CartModal = ({ cart, ...modalProps }) => {
  const openCheckout = () => {
    // window.open(checkoutState.webUrl) // opens checkout in a new window
    window.location.replace(storeData.checkoutState.webUrl); // opens checkout in same window
  };
  const { storeData } = useContext(ProductsContext);

  return (
    <div className={styles.cartModalContainer}>
      <Modal
        {...modalProps}
        topOffset="26px"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        {storeData?.checkoutState?.lineItems &&
        storeData?.checkoutState?.lineItems.length ? (
          <>
            <CartProducts />
            <hr />
            <div className={styles.cartFooter}>
              <Button href="/cart" icon={<FullScreenIcon width={24} />} outline>
                expand cart view
              </Button>
              <Button onClick={openCheckout}>checkout</Button>
            </div>
          </>
        ) : (
          <p className={styles.empty}>Your cart is empty</p>
        )}
      </Modal>
    </div>
  );
};

export default CartModal;
