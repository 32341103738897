import React from "react";
import Cart from "./cart";
import Layout from "../../components/layout";

const Checkout = () => {
  return (
    <Layout
      title="Your Cart | Temple Blends"
    >
      <Cart />
    </Layout>
  );
};

export default Checkout;
