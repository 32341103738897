import React, { useEffect, useContext, useCallback } from "react";
import styles from "./product.module.css";
import { CartContext } from "../../contexts/CartContext";
import { ProductsContext } from "../../contexts/ProductsContext";
import { useParams } from "react-router-dom";
import CartCount from "../../components/cart-count";
import {
  CartIcon,
  PlusCircleIcon,
  MinusCircleIcon,
} from "../../components/icons";

const ProductDetail = ({ product }) => {
  const {
    storeData,
    getProduct,
    productsList,
    addVariant,
    updateQuantityInCart,
  } = useContext(ProductsContext);
  const { productId } = useParams();
  const { addProduct, increase, decrease } = React.useContext(CartContext);
  const [filterProduct, setProductDetail] = React.useState({});
  const [productData, setProductData] = React.useState({});
  const [variantID, setvariantID] = React.useState("");
  let [quantity, setQuantity] = React.useState(1);
  let [count, setCount] = React.useState(0);

  const fetchProduct = useCallback(() => {
    getProduct(productId);
    if (
      storeData.product &&
      storeData.product.variants &&
      storeData.product.variants.length
    ) {
      setProductData({ ...storeData.product });
      if (!variantID) {
        setvariantID(storeData.product.variants[0].id);
      }
    }
  }, [productId, variantID, getProduct, setProductData, storeData]);
  const getCount = () => {
    const count =
      storeData?.checkoutState?.lineItems?.length &&
      storeData?.checkoutState?.lineItems.find((item) => {
        return item?.variant?.id === variantID;
      });
    return count?.quantity ? count?.quantity : 0;
  };

  useEffect(() => {
    fetchProduct();
    setCount(getCount());
  }, [fetchProduct]);

  useEffect(() => {
    const filterProduct = productsList.find((item) => {
      return item.id === productData?.id;
    });
    setProductDetail(filterProduct);
  }, [productsList, productData]);
  const selectVariant = (id) => {
    setvariantID(id);
    setCount(getCount());
  };
  const addItem = () => {
    addProduct(filterProduct);

    const lineItemsToAdd = [
      {
        variantId: variantID,
        quantity: parseInt(quantity, 10),
      },
    ];
    addVariant(lineItemsToAdd);
  };
  const isInCart = () => {
    const data =
      storeData?.checkoutState?.lineItems &&
      !!storeData?.checkoutState?.lineItems.find((item) => {
        return item?.variant?.product?.handle === productId;
      });

    return data;
  };
  const addQuantity = async () => {
    const variantData = storeData?.checkoutState?.lineItems.find((item) => {
      return item?.variant.id === variantID;
    });
    let count = variantData ? variantData.quantity + 1 : 1;

    increase(filterProduct);
    await setQuantity(count);
    if (variantData) {
      updateQuantityInCart(variantData?.id, count);
    } else {
      addItem();
    }
  };

  const minusQuantity = async () => {
    if (quantity >= 1) {
      const variantData = storeData?.checkoutState?.lineItems.find((item) => {
        return item?.variant.id === variantID;
      });
      let count = variantData.quantity - 1;

      decrease(filterProduct);
      await setQuantity(count);

      updateQuantityInCart(variantData?.id, count);
    }
  };

  const {
    title,
    type,
    dollar,
    description,
    Checkout_portion,
    productDiv,
    plusIcon,
    negativeIcon,
    checkoutButton,
    image2,
    addToCart,
    incrementContainer,
    productDetail,
    ProductImage,
    cartCountPosition,
    img,
    variantsDiv,
  } = styles;
  const imageSource =
    productData?.images?.length > 0 && productData?.images[0]?.src;
  const imageSource_2 =
    productData?.images?.length > 0 && productData?.images[1]?.src;
  const price =
    productData?.variants &&
    productData?.variants.find(({ id }) => id === variantID).price;

  return (
    <div className={productDiv}>
      <div>
        <div>
          <div
            className={ProductImage}
            style={{
              backgroundColor: filterProduct
                ? filterProduct?.theme?.brandColor
                : "hsl(19deg 66% 25%)",
            }}
          >
            <img alt={product?.id} src={imageSource} className={styles.img} />
          </div>
        </div>
      </div>

      <div className={productDetail}>
        <h1
          className={title}
          style={{
            color: filterProduct
              ? filterProduct?.theme?.title
              : "hsl(74deg 66% 87%)",
          }}
        >
          {productData?.title}
        </h1>
        <p
          className={type}
          style={{
            color: filterProduct
              ? filterProduct?.theme?.title
              : "hsl(74deg 66% 87%)",
          }}
        >
          {productData?.productType} <span className={dollar}>$</span>
          {price}{" "}
        </p>
        <p
          className={description}
          style={{
            color: filterProduct
              ? filterProduct?.theme?.description
              : "hsl(34deg 64% 71%)",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: productData?.descriptionHtml,
            }}
          />
          <div className={variantsDiv}>
            {productData?.variants?.length > 1 &&
              productData?.variants.map((item, index) => {
                return (
                  <div>
                    <p>
                      <input
                        type="radio"
                        id={item.title}
                        name="radio-group"
                        onClick={() => selectVariant(item.id)}
                        checked={item.id === variantID}
                      />
                      <label for={item.title}>{item.title}</label>
                    </p>
                  </div>
                );
              })}
          </div>
          <div className={Checkout_portion}>
            <button
              className={checkoutButton}
              data-in-cart={isInCart()}
              onClick={() => {
                !isInCart() && addItem();
              }}
            >
              {isInCart() && (
                <div className={cartCountPosition}>
                  <CartCount count={count} />
                </div>
              )}
              <CartIcon width={25} />
              <span className={addToCart}>
                {isInCart() ? "IN CART" : "ADD TO CART"}
              </span>
            </button>

            {isInCart() ? (
              <div className={incrementContainer}>
                <button
                  className={plusIcon}
                  onClick={() => {
                    addQuantity();
                  }}
                >
                  <PlusCircleIcon
                    width={"20px"}
                    background={"hsl(175deg 41% 18%)"}
                  />
                </button>

                <button
                  className={negativeIcon}
                  onClick={() => {
                    minusQuantity();
                  }}
                >
                  <MinusCircleIcon
                    width={"20px"}
                    background={"hsl(175deg 41% 18%)"}
                  />
                </button>
              </div>
            ) : null}
            <div>
              <img
                alt={product?.title}
                src={imageSource_2}
                className={image2}
              />
            </div>
          </div>
        </p>
      </div>
    </div>
  );
};

export default ProductDetail;
