import React from "react";
import Header from "../header";
import Footer from "../footer";
import { FooterWave } from "../icons";
import styles from "./layout.module.css";

import { Helmet } from "react-helmet-async";

const Layout = ({ title, description, children, page, background = "" }) => {
  const { FooterLogo, subpage, pageWrap } = styles;

  return (
    <div style={{ background }} className={pageWrap} data-page={page}>
      <Helmet>
        <title>{title || "Temple Blends | Craft Hemp Tea & Herbal Infusions"}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header
        isHomepage={page === "home"}
        showCartButton={page !== "cart"}
        page={page}
      />

      <div className={page !== "home" ? subpage : "land"}>{children}</div>
      {page !== "home" && (
        <div className={FooterLogo}>
          <FooterWave />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Layout;
