import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Instagram } from "../icons";
require("./footer.module.css");

const Footer = () => {
  return (
    <footer>
      <nav>
        <Link to="https://www.facebook.com/templeblendsatl">
          <Facebook width={24} />
        </Link>
        <Link to="https://www.instagram.com/templeblends/">
          <Instagram width={24} />
        </Link>
        <Link to="https://www.twitter.com/templeblends/">
          <Twitter width={24} />
        </Link>
        <span> // </span>
        <Link to="/about/brewing">How to Brew</Link>
        <Link to="/about/vaping">How to Vape</Link>
      </nav>
      <p>© 2021 TEMPLE BLENDS. ALL RIGHTS RESERVED</p>
    </footer>
  );
};

export default Footer;
