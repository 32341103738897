import React, { useContext, useState } from "react";
import Layout from "../../components/layout";
import ProductCard from "../../components/product-card/product-card";
import { ProductsContext } from "../../contexts/ProductsContext";
import { SendIcon, Logo } from "../../components/icons";
import styles from "./landing.module.css";

const Landing = () => {
  const {
    contactButton,
    contentConstraint,
    heroContainer,
    heroSubImg,
    heroHeading,
    heading,
    miniHeading,
    subHeading,
    intro,
    productContainer,
    introHeading,
    introContainer,
    introLightImg,
    introDarkImg,
    product,
    productHeading,
    productMiniHeading,
    productGrid,
    preFooter,
    preFooterContainer,
    preFooterBannerText,
    footer,
    footerHeading,
    footerHeadingMini,
    footerInnerContent,
    footerForm,
    footerFormGroup,
    newsletter,
    textArea,
  } = styles;
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [showMessage, setMessage] = useState(false);

  const { storeData, fetchAllCollections } = useContext(ProductsContext);

  const fetchProduct = React.useCallback(() => {
    const fetch = async () => {
      return await fetchAllCollections();
    };
    fetch();
  }, [fetchAllCollections]);

  React.useEffect(() => {
    fetchProduct();
    const hash = window.location.hash;
    if (hash) {
      document.querySelector(hash) &&
        document.querySelector(hash).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
    }
  }, [fetchProduct]);
  const onHandleChange = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userDetail.name && userDetail.email && userDetail.message) {
      const { name, email, message } = userDetail;
      const res = await fetch(
        "https://temple-blends-prod-default-rtdb.firebaseio.com/contact-form.json",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ name, email, message }),
        }
      );
      if (res.statusText === "OK") {
        setUserDetail({
          name: "",
          email: "",
          message: "",
        });
        setMessage(true);
      }
    }
  };
  const [teaCollection, bulkTeaCollection, ...otherCollections] =
    storeData?.collections || [];

  return (
    <Layout
      page="home"
      description="Infuse the soul with these delicious teas and aromatherapy blends"
    >
      <div className={heroContainer}>
        <div className={contentConstraint}>
          <img className={heroSubImg} src="img/teapot.png" alt="teapot setup" />
          <div className={heroHeading}>
            <h1 className={heading}>Temple Blends</h1>
            <h3 className={miniHeading}>craft tea and herbal infusions</h3>
            <h2 className={subHeading}>Infuse the Soul</h2>
          </div>
        </div>
      </div>
      <div className={intro}>
        <h1 className={introHeading}>
          uniquely blended CBD-grade hemp tea and aromatherapy blends with dried
          fruits, herbs, spices
        </h1>
        <div className={introContainer}>
          <img className={introLightImg} src="img/bodyTemple.png" alt="" />
          <img className={introDarkImg} src="img/circleImages.png" alt="" />
        </div>
      </div>
      <div className={product}>
        <div className={contentConstraint}>
          <h1 id="product-start" className={productHeading}>
            {teaCollection?.title}
          </h1>
          <h1 className={productMiniHeading}>{teaCollection?.description}</h1>
          <div className={productGrid}>
            {teaCollection?.products?.map((data, index) => {
              return (
                <div className={productContainer}>
                  <ProductCard product={data} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={product}>
        <div className={contentConstraint}>
          <h1 className={productHeading}>{bulkTeaCollection?.title}</h1>
          <h1 className={productMiniHeading}>
            {bulkTeaCollection?.description}
          </h1>
          <div className={productGrid}>
            {bulkTeaCollection?.products?.map((data, index) => {
              return (
                <div className={productContainer}>
                  <ProductCard product={data} index={index} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {otherCollections.map((collection, index) => {
        return (
          <div className={product}>
            <div className={contentConstraint}>
              <h1 className={productHeading}>{collection?.title}</h1>
              <h1 className={productMiniHeading}>{collection?.description}</h1>
              <div className={productGrid}>
                {collection?.products?.map((data, index) => {
                  return (
                    <div className={productContainer}>
                      <ProductCard product={data} index={index} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <div className={preFooter}>
        <div className={`${contentConstraint} ${preFooterContainer}`}>
          <h1 className={preFooterBannerText}>
            made with only the best organically grown hemp
          </h1>
        </div>
      </div>
      <div className={footer}>
        <div className={contentConstraint}>
          <h1 className={footerHeading}>
            Temple Blends is a rapidly growing company.
          </h1>
          <h1 className={footerHeadingMini}>
            Any feedback helps - tell us about your order, give us suggestions
            or just say hi!
          </h1>
          <div className={footerInnerContent}>
            <div
              style={{
                marginTop: "52px",
                marginRight: "32px",
              }}
            >
              <Logo width={"200px"} />
            </div>

            <div className={footerForm}>
              {showMessage ? (
                <h4>Your message was successfully sent.</h4>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className={footerFormGroup}>
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={onHandleChange}
                      required
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={onHandleChange}
                      required
                    />
                  </div>
                  <div>
                    <textarea
                      name="message"
                      id=""
                      cols="51"
                      rows="4"
                      placeholder="What’s on your mind?"
                      onChange={onHandleChange}
                      className={textArea}
                      required
                    ></textarea>
                  </div>
                  <div className={newsletter}>
                    <input
                      type="checkbox"
                      id="newsletter-signup"
                      name="newsletter-signup"
                    />
                    <label for="newsletter-signup">
                      Let me know about new products and website features
                    </label>
                    <button id="contact-button" className={contactButton}>
                      <SendIcon width={15} />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Landing;
