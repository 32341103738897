export const dummyProducts = [
  {
    id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwODI4NzQ1MDMzNDM=",
    theme: {
      primary: "#bf353b",
      class: "orangeSpice",
      description: "hsl(34deg 64% 71%)",
      brandColor: "hsl(19deg 66% 25%)",
      background: "#943900",
      gradient: "linear-gradient(90deg, rgba(148,57,0,1) 66%, rgba(131,50,1,1) 100%)",
      title: "hsl(74deg 66% 87%)",
    },
  },
  {
    id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwODI4ODU0NDc4NTU=",
    theme: {
      primary: "#60282E",
      class: "blueberryLavender",
      description: "#b29cbc",
      brandColor: "#681e28",
      background: "#7c081d",
      gradient: "linear-gradient(90deg, rgb(140, 3, 28) 66%, rgb(106 3 21) 100%)",
      title: "#b29cbc",
    },
  },
  {
    id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwODI4NzQwMTE4MjM=",
    theme: {
      primary: "#60282E",
      class: "hibiscusChamomile",
      description: "hsl(34deg 64% 71%)",
      brandColor: "rgb(97 23 20)",
      background: "#943900",
      gradient: "linear-gradient(90deg, rgb(193 80 67) 66%, rgb(161 51 63) 100%)",
      title: "hsl(74deg 66% 87%)",
    },
  },
  {
    id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwODI4NzQ1MzYxMTE=",
    theme: {
      primary: "#bf353b",
      class: "orangeSpice",
      description: "hsl(34deg 64% 71%)",
      brandColor: "hsl(19deg 66% 25%)",
      background: "#943900",
      gradient: "linear-gradient(90deg, rgba(148,57,0,1) 66%, rgba(131,50,1,1) 100%)",
      title: "hsl(74deg 66% 87%)",
    },
  },
  {
    id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwODI4NzM2ODQxNDM=",
    theme: {
      primary: "#60282E",
      class: "blueberryLavender",
      description: "#b29cbc",
      brandColor: "#681e28",
      background: "#7c081d",
      gradient: "linear-gradient(90deg, rgb(140, 3, 28) 66%, rgb(106 3 21) 100%)",
      title: "#b29cbc",
    },
  },
  {
    id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwODI4NzQ0Mzc4MDc=",
    theme: {
      primary: "#60282E",
      class: "hibiscusChamomile",
      description: "hsl(34deg 64% 71%)",
      brandColor: "rgb(97 23 20)",
      background: "#943900",
      gradient: "linear-gradient(90deg, rgb(193 80 67) 66%, rgb(161 51 63) 100%)",
      title: "hsl(74deg 66% 87%)",
    },
  },
];

export const PageImages = [
  {
    id: "Z2lkOi8vc2hvcGlmeS9QYWdlLzg2NjExODUzNDg3",
    img: "/img/vaping_thumb.png",
  },
  {
    id: "Z2lkOi8vc2hvcGlmeS9QYWdlLzg2NTQwMzIwOTQz",
    img: "/img/brewing_thumb.png",
  },
];
