import React, { useContext } from "react";
import styles from "./checkout.module.css";
import { ProductsContext } from "../../contexts/ProductsContext";
import CartProducts from "../../components/cart-products/";
import { Link } from "react-router-dom";

const LeftSection = () => {
  const {
    description_belowCart,
    smallCircle,
    incrementContainerOrderTotal,
    shoppingButton,
    shoppingButton2,
    container,
    continueShopping,
    wrapper,
    btnWrapper,
    btnWrapper2,
    orderTotal,
  } = styles;
  const { storeData } = useContext(ProductsContext);
  const openCheckout = (e) => {
    e.preventDefault();
    // window.open(checkoutState.webUrl) // opens checkout in a new window
    window.location.replace(storeData.checkoutState.webUrl); // opens checkout in same window
  };

  let total = 0;
  const data =
    storeData?.checkoutState?.lineItems &&
    storeData?.checkoutState?.lineItems.length &&
    storeData?.checkoutState?.lineItems.forEach((product) => {
      if (product?.quantity) total = total + product?.quantity;
    });

  return (
    <div className={wrapper}>
      <h2>
        Your Cart ({total} items)
      </h2>

      <div>
        {/* product cup section 1 */}
        <div className={container}>
          {storeData?.checkoutState?.lineItems &&
          storeData?.checkoutState?.lineItems.length ? (
            <CartProducts />
          ) : (
            <div style={{ textAlign: "center", fontSize: "18px" }}>
              Your Cart is Empty
            </div>
          )}
        </div>
      </div>

      <div
        className={description_belowCart}
        style={{ color: "hsl(34deg 64% 71%)" }}
      >
        <div className={btnWrapper2}>
          <div>Order Total</div>

          <div className={incrementContainerOrderTotal}>
            <div className={orderTotal}>
              $
              {storeData?.checkoutState?.lineItems &&
              storeData?.checkoutState?.lineItems.length
                ? `${
                    Number(storeData?.checkoutState?.subtotalPrice) +
                    Number(storeData?.checkoutState?.totalTax)
                  } 
                  (including tax) `
                : 0}
            </div>
            shipping will be calculated at next step
          </div>
          <div>
            <span className={smallCircle}></span>
          </div>
        </div>
      </div>

      <div
        className={description_belowCart}
        style={{ color: "hsl(34deg 64% 71%)" }}
      >
        <div className={btnWrapper}>
          <div className={shoppingButton2}>
            <Link to="/#product-start">
              <span className={continueShopping}>Continue Shopping</span>
            </Link>
          </div>

          <div className={incrementContainerOrderTotal}>
            <div className={shoppingButton} onClick={openCheckout}>
              <span className={continueShopping}>Checkout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSection;
