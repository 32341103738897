import React, { useContext, useCallback, useState, useEffect } from "react";
import Layout from "../../components/layout";
import { useParams } from "react-router-dom";
import { ProductsContext } from "../../contexts/ProductsContext";
// import NotFound from "../not-found";
import ProductDetail from "./product-detail";
const Product = () => {
  const { productsList, getProduct, storeData } = useContext(ProductsContext);
  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const product = productsList.find(
    (product) => product.id === productData?.id
  );

  const fetchProduct = useCallback(() => {
    getProduct(productId);
    if (
      storeData.product &&
      storeData.product.variants &&
      storeData.product.variants.length
    ) {
      setProductData({ ...storeData.product });
    }
  }, [productId, getProduct, setProductData, storeData]);

  useEffect(() => {
    fetchProduct();
    window.scrollTo(0, 0);
  }, [fetchProduct]);

  return Object.keys(productData).length < 1 ? (
    <p>Loading...</p>
  ) : (
    <Layout
      title={`${productData.title} ${productData.productType} | Temple Blends`}
      description={productData.description}
      page="product"
      background={
        product
          ? product.theme
            ? product.theme.gradient || product.theme.background
            : "#fff"
          : "#943900"
      }
    >
      <ProductDetail product={product} />
    </Layout>
  );
};

export default Product;
