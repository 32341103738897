import React from "react";
import styles from "./product-card.module.css";
import { ProductsContext } from "../../contexts/ProductsContext";
import { useHistory } from "react-router-dom";

function ProductCard({ product, index }) {
  const history = useHistory();
  const { cardContainer, cardHeading, cardImg, cardImg2 } = styles;
  const { storeData, setStore } = React.useContext(ProductsContext);
  const handleRedirect = async () => {
    await setStore({ ...storeData, product: {} });
    const productName = product.handle;

    history.push(`/product/${productName}`);
  };
  return (
    <div
      className={cardContainer}
      onClick={handleRedirect}
      style={{ marginTop: index > 2 && "50px" }}
    >
      <h1 className={cardHeading}>{product?.title}</h1>
      <img
        className={`${product.productType === "Bulk Tea" ? cardImg2 : cardImg}`}
        src={product?.images[0].src}
        alt=""
      />
    </div>
  );
}

export default ProductCard;
