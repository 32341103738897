import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import { HelmetProvider } from "react-helmet-async";
import ProductsContextProvider from "./contexts/ProductsContext";
import CartContextProvider from "./contexts/CartContext";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/link-context";

const httpLink = new HttpLink({
  uri: "https://temple-blends.myshopify.com/api/graphql",
});
const middlewareLink = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": "85dfffd159cb5cd5111258a915eb1751",
  },
}));
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: middlewareLink.concat(httpLink),
});
const firebaseConfig = {
  apiKey: "AIzaSyCGcrp7qldoe9CFbkwQgwQ5Gxxq9ozo7Iw",
  authDomain: "temple-blends-prod.firebaseapp.com",
  databaseURL: "https://temple-blends-prod-default-rtdb.firebaseio.com",
  projectId: "temple-blends-prod",
  storageBucket: "temple-blends-prod.appspot.com",
  messagingSenderId: "305483038255",
  appId: "1:305483038255:web:65d3977351994e6fb590da",
  measurementId: "G-JPXWEJKJ1L"
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(
  <ApolloProvider client={client}>
    <HelmetProvider>
      <ProductsContextProvider>
        <CartContextProvider>
          <Routes />
        </CartContextProvider>
      </ProductsContextProvider>
    </HelmetProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
