import React, { useState, useEffect } from "react";
import styles from "./about.module.css";
import Layout from "../../components/layout";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import SideBar from "./side-bar";
const About = () => {
  const { id } = useParams();

  const Fetch_Pages = gql`
    {
      pages(first: 5) {
        edges {
          node {
            id
            title
            body
            handle
          }
        }
      }
    }
  `;
  const { data: allPages } = useQuery(Fetch_Pages);
  const [pageDetail, setPageDetail] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    const details = allPages?.pages?.edges.find((item) => {
      return item.node?.handle === id;
    });
    setPageDetail(details);
  }, [allPages, id]);

  const {
    checkOutPage_topClass,
    contentContainer,
    sidebarContainer,
    heading1,
    flexDiv,
    readMore,
    onlinePages,
    mainContent,
  } = styles;

  return Object.keys(pageDetail || {}).length < 1 ? (
    <p>Loading...</p>
  ) : (
    <Layout title={`${pageDetail.node.title} | Temple Blends`}>
      <div className={checkOutPage_topClass}>
        <div className={flexDiv}>
          <div className={contentContainer}>
            <p className={heading1}>
              {" "}
              {pageDetail.node && pageDetail.node.title}
            </p>
            <div
              className={mainContent}
              dangerouslySetInnerHTML={{
                __html: pageDetail.node && pageDetail.node.body,
              }}
            />
          </div>
          <div className={sidebarContainer}>
            <p className={readMore}>More Reading</p>
            <div style={{ display: "flex" }}>
              <br />
              <div className={onlinePages}>
                {allPages?.pages?.edges.map((item, index) => {
                  return (
                    id !== allPages?.pages?.edges[index]?.node?.handle && (
                      <div key={index}>
                        <SideBar page={item} />
                        {index !== allPages?.pages?.edges.length - 1 ||
                          (allPages?.pages?.edges.length === 1 && (
                            <hr style={{ margin: "0.5rem 1rem" }} />
                          ))}
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default About;
