import React from "react";

export const MinusCircleIcon = ({ width, background }) => (
  <svg
    width={width}
    fill={background}
    viewBox="0 0 20 20"
    style={{ background: "#FFFF", borderRadius: "50%" }}
  >
    <path
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
      clipRule="evenodd"
      fillRule="evenodd"
    ></path>
  </svg>
);

export const PlusCircleIcon = ({ width, background }) => (
  <svg
    width={width}
    fill={background}
    viewBox="0 0 20 20"
    style={{ background: "#FFFF", borderRadius: "50%" }}
  >
    <path
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
      clipRule="evenodd"
      fillRule="evenodd"
    ></path>
  </svg>
);

export const CartIcon = ({ width, style }) => (
  <svg width={width} style={style} viewBox="0 0 32 27.5">
    <path d="M16,13.75a.63.63,0,1,0-.62-.63A.63.63,0,0,0,16,13.75Z" />
    <path d="M29.5,8.75H25.8L20.12.93a1.88,1.88,0,0,0-3.26,1.86l.05.07,4.46,5.89H10.63l4.46-5.89.05-.07A1.88,1.88,0,0,0,11.88.93L6.2,8.75H2.5a2.5,2.5,0,0,0-.8,4.87.65.65,0,0,0,.38.13h.09a.64.64,0,0,1,.53.51L4.82,26A1.89,1.89,0,0,0,6.67,27.5H25.33A1.89,1.89,0,0,0,27.18,26l2.12-11.7a.65.65,0,0,1,.54-.51h.08a.62.62,0,0,0,.38-.13,2.5,2.5,0,0,0-.8-4.87ZM17.94,2.15a.61.61,0,0,1,.26-.82.62.62,0,0,1,.85.24.21.21,0,0,0,0,.06l5.17,7.12H22.94Zm-5-.52a.21.21,0,0,0,0-.06.63.63,0,0,1,1.11.58l-5,6.6H7.74ZM29.5,12.5h-11a.63.63,0,0,0-.63.62.62.62,0,0,0,.63.62h9.65c0,.1-.06.19-.08.29L26,25.74a.64.64,0,0,1-.62.51H6.67a.64.64,0,0,1-.62-.51L3.93,14a2.45,2.45,0,0,0-.08-.29H13.5a.63.63,0,1,0,0-1.25H2.5a1.25,1.25,0,0,1,0-2.5h27a1.25,1.25,0,0,1,0,2.5Z" />
    <path d="M16,15.62a1.88,1.88,0,0,0-1.87,1.88v5a1.87,1.87,0,0,0,3.74,0v-5A1.87,1.87,0,0,0,16,15.62Zm.62,6.88a.62.62,0,0,1-1.24,0v-5a.62.62,0,1,1,1.24,0Z" />
    <path d="M22.25,24.37a1.87,1.87,0,0,0,1.87-1.87v-5a1.87,1.87,0,0,0-1.87-1.88,1.89,1.89,0,0,0-1.88,1.88v5A1.88,1.88,0,0,0,22.25,24.37Zm-.63-6.87a.63.63,0,0,1,.63-.63.63.63,0,0,1,.62.63v5a.62.62,0,0,1-.62.62.63.63,0,0,1-.63-.62Z" />
    <path d="M9.75,15.62A1.88,1.88,0,0,0,7.88,17.5v5a1.88,1.88,0,0,0,3.75,0v-5A1.88,1.88,0,0,0,9.75,15.62Zm.63,6.88a.63.63,0,0,1-.63.62.62.62,0,0,1-.62-.62v-5a.63.63,0,0,1,.62-.63.63.63,0,0,1,.63.63Z" />
  </svg>
);

export const CloseIcon = ({ width }) => (
  <svg width={width} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
  </svg>
);

export const FullScreenIcon = ({ width }) => (
  <svg width={width} fill="currentColor" viewBox="0 0 24 24">
    <rect fill="none" height="24" width="24" />
    <polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" />
  </svg>
);

export const TrashIcon = ({ width }) => (
  <svg width={width} fill="currentColor" viewBox="0 0 20 20">
    <path
      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
      clipRule="evenodd"
      fillRule="evenodd"
    ></path>
  </svg>
);

export const SendIcon = ({ width }) => (
  <svg width={width} fill="currentColor" viewBox="0 0 24 17.5">
    <path
      d="M7.3,17.5L24,0.2L0,0l2.8,6.8l15.4-4.1l-13.7,8L7.3,17.5z"
    />
  </svg>
);

export const BackHomeIcon = ({ width }) => (
  <svg width={width} fill="currentColor" viewBox="0 0 37.4 26.4">
    <path
      d="M36.9,11.7l-2.3-2.1V2.5c0-0.5-0.4-0.8-0.8-0.8h-5.6c-0.5,0-0.8,0.4-0.8,0.8V3l-2.8-2.6c-0.5-0.5-1.4-0.5-1.9,0L10.1,11.7
		c-0.4,0.4-0.6,1-0.4,1.6c0.2,0.6,0.7,0.9,1.3,0.9h2v11.4c0,0.5,0.4,0.8,0.8,0.8h6.8c0.5,0,0.8-0.4,0.8-0.8v-6.9h4v6.9
		c0,0.5,0.4,0.8,0.8,0.8h6.8c0.5,0,0.8-0.4,0.8-0.8V14.2h2c0.6,0,1.1-0.4,1.3-0.9C37.5,12.7,37.3,12.1,36.9,11.7z M33.1,12.6
		c-0.5,0-0.8,0.4-0.8,0.8v11.4h-5.2v-6.9c0-0.5-0.4-0.8-0.8-0.8h-5.6c-0.5,0-0.8,0.4-0.8,0.8v6.9h-5.2V13.4c0-0.5-0.4-0.8-0.8-0.8
		h-2.3L23.5,1.7l4.1,3.7c0.2,0.2,0.6,0.3,0.9,0.1c0.3-0.1,0.5-0.4,0.5-0.7V3.3h4v6.6c0,0.2,0.1,0.4,0.3,0.6l2.2,2L33.1,12.6
		L33.1,12.6z"
    />
    <polygon points="4,12.9 0,16.9 4,20.9 	" />
  </svg>
);

export const HomeIcon = ({ width, style }) => (
  <svg width={width} style={style} viewBox="0 0 37.4 26.4">
    <path
      d="M36.9,11.7l-2.3-2.1V2.5c0-0.5-0.4-0.8-0.8-0.8h-5.6c-0.5,0-0.8,0.4-0.8,0.8V3l-2.8-2.6c-0.5-0.5-1.4-0.5-1.9,0L10.1,11.7
		c-0.4,0.4-0.6,1-0.4,1.6c0.2,0.6,0.7,0.9,1.3,0.9h2v11.4c0,0.5,0.4,0.8,0.8,0.8h6.8c0.5,0,0.8-0.4,0.8-0.8v-6.9h4v6.9
		c0,0.5,0.4,0.8,0.8,0.8h6.8c0.5,0,0.8-0.4,0.8-0.8V14.2h2c0.6,0,1.1-0.4,1.3-0.9C37.5,12.7,37.3,12.1,36.9,11.7z M33.1,12.6
		c-0.5,0-0.8,0.4-0.8,0.8v11.4h-5.2v-6.9c0-0.5-0.4-0.8-0.8-0.8h-5.6c-0.5,0-0.8,0.4-0.8,0.8v6.9h-5.2V13.4c0-0.5-0.4-0.8-0.8-0.8
		h-2.3L23.5,1.7l4.1,3.7c0.2,0.2,0.6,0.3,0.9,0.1c0.3-0.1,0.5-0.4,0.5-0.7V3.3h4v6.6c0,0.2,0.1,0.4,0.3,0.6l2.2,2L33.1,12.6
		L33.1,12.6z"
    />
  </svg>
);

export const Logo = ({ width, style }) => (
  <svg
    width={width}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 379.76 280.13"
  >
    <path d="M140.86 27.58c-18 1.23-38.36 7.86-48.73 22.85a3.78 3.78 0 00-.64 2.12c.13 26.71 5.14 55.57 20.86 77.16a3.77 3.77 0 01-1.78 5.77c-9.52 3.4-14.87 6.29-24.7 8.65-3.23.78-10.11-2.65-8.48-5.55 11-19.52-5.58-44.86-11.46-66.43-.8-3 1-3.8-.94-1.47a68.12 68.12 0 00-14.53 30.14 3.77 3.77 0 01-7 1c-23.7-44.55 21.06-71 60-88.78 22.69-9.87 34.23-17 40.82 9.88a3.77 3.77 0 01-3.42 4.66zM154.8 29.75a72.36 72.36 0 0015.16 0 7.15 7.15 0 017.93 6.83c1 26.15 4.42 56.83 4.45 82.74a2.92 2.92 0 002.87 2.91c4.58.09 10.58.41 14 .62a2.93 2.93 0 003.08-2.59A379 379 0 00201.4 34a2.92 2.92 0 013.13-3.28 2.93 2.93 0 012.7 2.78c1 22.46 1.09 62.56.15 85.24a2.92 2.92 0 002.76 3c3.6.19 9.72.68 13.39 1a2.92 2.92 0 003.21-2.76c1.08-21.54 2.63-61.18 3.72-82.75a2.93 2.93 0 013.34-2.75l1.23.18a2.94 2.94 0 012.51 2.94 601.1 601.1 0 01-7.1 83.29 2.92 2.92 0 002.67 3.4l18 1.32a2.94 2.94 0 003.13-2.59c1.48-13.3 8.13-70.71 15.28-99.27.67-2.69-35-13.76-38.35-14.79A201 201 0 00154.38.75a2.94 2.94 0 01-.42 0c-17.55-1.16-5.11 17.39-1.66 27a3 3 0 002.5 2z" />
    <path d="M133.76 94.81a2.61 2.61 0 011.46-4.26l23.2-4a1.38 1.38 0 001.25-1.63l-1-5.17a4.06 4.06 0 00-4.63-3.28L130.42 80a4.08 4.08 0 01-4.53-2.85l-1.67-5.5a6.18 6.18 0 014.78-7.92l33.64-6.09a5.9 5.9 0 004.7-6.64c-.67-4.51-1.65-10.61-2.46-13.89a3.32 3.32 0 00-3.11-2.54c-5.37-.21-20.93-1.86-31.87.32-29.44 5.85-29.51 12.11-29.44 23.25.11 15.31-3.34 44 16.16 62.82 4.19 4.05 5.92 5.71 7.55 6.4a2.74 2.74 0 001.6.15c3.87-.82 36-4.82 46.29-6.76 3.24-.61 10.31 0 6.44-11.11l-.56-1.59c-1.33-3.85-3.42-8.17-7.45-7.63-6.85.93-23.78 2.59-28.53 3.61a1 1 0 01-.67-.14M321.36 67.22c-7.22 46.35-46.57 53.61 26.65 67l.2.05c4.9 1.68 1.28 5.5-1.39 10.23a1.29 1.29 0 01-.64.56c-15.75 5.85-67.56-16.73-68-12.5 20.23-74.26 4.57-29.61 41-66.4a1.27 1.27 0 012.18 1.06z" />
    <path d="M379.4 86.33c2.64-3.84-10-9.62-23.64-16.36-6.75-3.34-14.27-6.45-18.51-8.91a4.33 4.33 0 00-6.07 1.94c-7.26 15.35-11.17 28-18.77 43.12a10.59 10.59 0 005.2 14.47c8.87 3.89 20.23 5.53 29.65 9.41a6.41 6.41 0 007.82-2.34 59.53 59.53 0 003.35-5.52 2.55 2.55 0 00-1.64-3.62c-7.9-2.1-23.13-6.1-30.8-9a.92.92 0 01-.46-1.33 27.06 27.06 0 002.45-6.14 1 1 0 011.22-.67c4 1.18 17.4 5.28 23.15 6.83 1.92.51 3.27 2 4.14.26l4.35-6.84c1.69-3.46-1.86-1.86-10.46-5.71-5.95-2.66-13.05-4.06-16.45-5.88a.92.92 0 01-.4-1.22c.86-1.72 2.92-5.81 3.79-8.07.22-.57.88-1.4 1.44-1.14 19.6 9 24.74 10.32 30.73 15.49 1.68 1.45 3.86.16 4.42-1.51 1.57-4.59 3.09-3.75 5.49-7.26zM8.92 148.72c-15.54 2.87-8.76 21.52 4.93 19.8C32.1 165.79 47 151.67 65.19 148.7c4.73-.91 9.6-4.83 2.68-5.79a159.15 159.15 0 00-58.95 5.81zM141.56 164.8C192 263.28 123.82 287 197.44 278.57c12.32 1.05 16.45-10.19 13.9-21.08-2-5.33.75-15.56-5.3-17.83-6.44-2.2-17.62 6.23-18.63-4.49-5.23-21.37-5.77-44.46-16.17-64.17-6.6-11.48-13-24.06-23.11-32.81-4.58-3-14.82-2.06-16.6 4.29-1.59 5.7 7.47 17.32 10.03 22.32z" />
    <path d="M184.79 197c3.54 10.49 4.19 22.59 9.74 32.15 8.15 5.89 23.87 2.25 30.82-4.3 4.62-5.82 4.87-15.63 2.45-23.22a6.58 6.58 0 00-5.76-4.59c-11.56-1-13.61 13-21.88-10.37a4.92 4.92 0 013.3-6.37 18.26 18.26 0 003.44-1.3c6.64-3.15 3.68-12.43-3.25-10.2-3.89 1.25-7 2.23-9.12 0a4.5 4.5 0 01.71-6.6c11.54-8.72 22.95-1.39 23.83-5.42 1.33-6-.56-11.12-.93-16.38-.76-4.52-5.63-7.11-10.17-6.55-10.06 1-35.09-1.36-38.24 9.56-.93 7.48 5.13 18.05 7.24 25.14 2.81 9.45 5.31 18.96 7.82 28.45z" />
    <path d="M229.89 180s12.58 48.23-7.38 51.86q-.13 17.94.17 35.88c32.06 3.43 21.77-12.89 23.19-31.66a64.12 64.12 0 0110.07 25.55 110 110 0 0017.38-2.63c-.65-36.67-.57-77.06-1.21-113.72-.51-5.36-12.5-1.06-16.67-1.63-9.83-1.23-2.29 57.14.69 63.87-9.3-21-10.07-42.86-12.62-65.18-.39-3.42-16.07-7.08-19.86-5.25-6.65 7.47 6.24 42.91 6.24 42.91zM307.56 224.87c-.16-14.48 5.92-78.24-13.7-75.78-9.45-2.08-17.32-5.52-18.77 1-.62 34.8 1.86 72.09 3.15 104.61.1 2.52 4 4.09 6.19 2.83 23.13-13.39 24.57 5.1 23.13-32.66zm-18.8-49.28c2.24-1.39 5.72-2 6.21.62 2.85 15 3.35 33.94 2.18 49.32v.25c.09 5.53-3.07 5-6.36 6.89M313.67 50.79C308.88 38 291.4 31.93 279.7 26c-8.34-2.63-16.09 57.11-16.09 57.11l-5.16 40.15a3.76 3.76 0 002.6 4.06l7.43 2.32a3.15 3.15 0 004-2.24c4.11-16.17 8.35-36.58 12.72-52 10.95 4.33 35.29-4.66 28.47-24.61zm-19.62 11.33l-4.52-.66c-1-.14-3.71-.45-3.88-1.45-.6-3.49.7-11.35 7.45-7.6a7.89 7.89 0 013.06 7.42c-.24 1.31-.79 2.48-2.11 2.29zM133.12 192.72c14.67-16.18 2-50.86-20.55-52.32-13-.85-27.73 9.27-37.94 16.56-15.63 12.19-8.54 35.74-3.42 51.86 5.38 14.31 13.63 27.33 21.83 40.23 6.19 7.34 9.32 23.87 21.9 18.43 16.33-5.78 28.1 14.91 39.28 1 11.78-20.28 1.71-74.48-21.1-75.76zm-29.19-7.79l-8.68-.34a1.25 1.25 0 01-.83-.24 1.08 1.08 0 01-.24-.84c.22-5.69 10.29-7.46 14.59-6.46 7.83 1.81-.07 8.07-4.84 7.88zm35.86 37.71c-1.5 3.46-8 5.34-11.12 6.85a9.31 9.31 0 01-2.82 1c-6.95 1 .6-6.61 2.67-7.86a18.88 18.88 0 018.64-2.91c2.42.01 3.72.44 2.63 2.92z" />
    <path d="M328.52 173.53a4.49 4.49 0 005.54 5.52 26.12 26.12 0 007.52-4.08c4.35-3.11 6.84-10.56.62-16.78-3.2-3.2-22.37-12.43-28-1.86s-8.08 21.13-3.73 31.07 11.81 3.73 14.91 8.7c2 3.16 0 12.43-7.45 13.05s-8.08-.62-8.08 9.94-1.25 29.21 3.1 26.72 33.56-38.52 33.56-47.85-10.74-13.45-15.51-14.91c-8.08-2.49-9.33-5-9.33-9.94s-.05-5.81 3.11-5.6c5.41.36 4.57 3.01 3.74 6.02z" />
  </svg>
);

export const FooterWave = ({ width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1201 271.4"
  >
    <style type="text/css"></style>
    <path
      fill="#07382e49"
      d="M0,8.8c27.1-26.7,66.6,11.8,136.6,57.8c63,41.4,141.9,46.1,198.2,4.7c8.5-6.3,17.6-11.8,27.4-15.8
	C435,25.2,527.6,52.2,637,127.6c62,46.3,120.2,94.1,173.7,143.8h123c74.6-68.9,164.5-88.5,267.4-66.4v66.4H0V8.8z"
    />
  </svg>
);

export const Facebook = ({ width }) => (
  <svg width={width} viewBox="0 0 24 24">
    <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
  </svg>
);

export const Instagram = ({ width }) => (
  <svg width={width} viewBox="0 0 24 24">
    <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
  </svg>
);

export const Twitter = ({ width }) => (
  <svg width={width} viewBox="0 0 24 24">
    <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
  </svg>
);
