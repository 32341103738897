import React, { useContext } from "react";
import { ProductsContext } from "../../contexts/ProductsContext";
import CartItem from "../cart-item";

const CartProducts = () => {
  const { storeData } = useContext(ProductsContext);

  return (
    <div>
      {storeData?.checkoutState?.lineItems &&
        storeData?.checkoutState?.lineItems.length &&
        storeData?.checkoutState?.lineItems.map((product) => (
          <CartItem key={product.id} product={product} />
        ))}
    </div>
  );
};

export default CartProducts;
