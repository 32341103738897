import React, { createContext, useState } from "react";
import { dummyProducts, PageImages } from "../services/dummy";
import Client from "shopify-buy";
export const ProductsContext = createContext();

const client = Client.buildClient({
  storefrontAccessToken: "85dfffd159cb5cd5111258a915eb1751",
  domain: "temple-blends.myshopify.com",
});
const initialState = {
  isCartOpen: false,
  cartCount: 0,
  collections: [],
  products: [],
  featured: [],
  product: {},
  checkoutState: {},
  shop: {},
};
const ProductsContextProvider = ({ children }) => {
  const [productsList] = useState(dummyProducts);
  const [storeData, setStore] = useState(initialState);
  const fetchAllCollections = React.useCallback(async () => {
    try {
      const collections = await client.collection.fetchAllWithProducts();
      if (!storeData.collections.length) {
        setStore({ ...storeData, collections: collections });
      } else return;
    } catch (e) {
      console.log(e, "error");
    }
  }, [storeData]);
  const fetchAllProducts = React.useCallback(async () => {
    try {
      const products = await client.product.fetchAll();
      if (!storeData.products.length) {
        setStore({ ...storeData, products: products });
      } else return;
    } catch (e) {
      console.log(e, "error");
    }
  }, [storeData]);
  const getProduct = React.useCallback(
    async (title) => {
      try {
        const product = await client.product.fetchByHandle(title);
        if (storeData.product && !Object.keys(storeData.product).length) {
          setStore({ ...storeData, product: product });
        } else return;
      } catch (e) {
        console.log(e, "error");
      }
    },
    [storeData]
  );
  const getCartProduct = React.useCallback(async (id) => {
    try {
      const product = await client.product.fetch(id);
      if (product.productType) {
        return product.productType;
      } else return;
    } catch (e) {
      console.log(e, "error");
    }
  }, []);
  const fetchCheckout = React.useCallback(async (checkoutId) => {
    try {
      const checkout = await client.checkout.fetch(checkoutId);

      setStore({ ...storeData, checkoutState: checkout });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const createCheckout = React.useCallback(async () => {
    try {
      const checkout = await client.checkout.create();
      localStorage.setItem("checkout", checkout.id);
      setStore({ ...storeData, checkoutState: checkout });
    } catch (e) {
      console.log(e);
    }
  }, []);

  // Adds variants to cart/checkout
  const addVariant = async (lineItemsToAdd) => {
    try {
      const response = await client.checkout.addLineItems(
        storeData.checkoutState.id,
        lineItemsToAdd
      );
      setStore({ ...storeData, checkoutState: response });
    } catch (e) {
      console.log(e, "error");
    }
  };

  // Updates quantity of line items in cart and in checkout state
  const updateQuantityInCart = async (lineItemId, quantity) => {
    try {
      const lineItemsToUpdate = [
        { id: lineItemId, quantity: parseInt(quantity, 10) },
      ];

      const response = await client.checkout.updateLineItems(
        storeData.checkoutState.id,
        lineItemsToUpdate
      );

      setStore({ ...storeData, checkoutState: response });
    } catch (e) {
      console.log(e);
    }
  };

  // remove item from cart
  const removeLineItemInCart = async (lineItemId) => {
    const response = await client.checkout.removeLineItems(
      storeData.checkoutState.id,
      [lineItemId]
    );
    setStore({ ...storeData, checkoutState: response });
  };
  React.useEffect(() => {
    if (localStorage.checkout) {
      fetchCheckout(localStorage.checkout);
    } else {
      createCheckout();
    }
  }, [createCheckout, fetchCheckout]);

  return (
    <ProductsContext.Provider
      value={{
        storeData,
        fetchAllProducts,
        fetchAllCollections,
        getProduct,
        productsList,
        PageImages,
        getCartProduct,
        createCheckout,
        addVariant,
        updateQuantityInCart,
        removeLineItemInCart,
        setStore,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsContextProvider;
