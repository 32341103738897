import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { CartIcon, HomeIcon } from "../icons";
import CartModal from "../cart-modal";
import CartCount from "../cart-count";
import { useParams } from "react-router-dom";
import styles from "./header.module.css";
import { ProductsContext } from "../../contexts/ProductsContext";
import { Logo } from "../../components/icons";

const Header = ({ isHomepage, showCartButton, page }) => {
  const { productId } = useParams();

  const { cartItems } = useContext(CartContext);
  const {
    storeData,
    getProduct,
    productsList,
  } = useContext(ProductsContext);
  const [show, toggle] = useState(false);
  const [filterProduct, setProductDetail] = React.useState({});
  const [productData, setProductData] = React.useState({});

  const {
    cart,
    cartCountPosition,
    home,
    companyName,
    wide,
    landing,
    logo,
    quote,
    brandingWrap,
    subName,
  } = styles;

  const fetchProduct = useCallback(() => {
    getProduct(productId);
    if (
      storeData.product &&
      storeData.product.variants &&
      storeData.product.variants.length
    ) {
      setProductData({ ...storeData.product });
    }
  }, [productId, getProduct, setProductData, storeData]);

  useEffect(() => {
    if (productId) fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    const filterProduct = productsList.find((item) => {
      return item.id === productData?.id;
    });
    setProductDetail(filterProduct);
  }, [productsList, productData]);
  const getCount = () => {
    let total = 0;
    const data =
      storeData?.checkoutState?.lineItems &&
      storeData?.checkoutState?.lineItems.length &&
      storeData?.checkoutState?.lineItems.forEach((product) => {
        if (product?.quantity) total = total + product?.quantity;
      });
    return total;
  };
  return (
    <header className={isHomepage ? "home" : wide}>
      {!isHomepage && (
        <Link className={home} to="/">
          <HomeIcon width={40}   
          style={{
            fill:
            filterProduct &&
            filterProduct.theme &&
            filterProduct.theme.description
              ? filterProduct.theme.description
              : "#4c8b58"}}
            
         />
          <p
            className={companyName}
            style={{
              color:
                filterProduct &&
                filterProduct.theme &&
                filterProduct.theme.description
                  ? filterProduct.theme.description
                  : "hsl(175, 41%, 18%)"
            }}
          >
            <span>Temple Blends</span>
            <span className={subName}>craft tea and herbal infusions</span>
          </p>
        </Link>
      )}
      <button
        className={`${isHomepage ? landing : ""} ${cart}`}
        onClick={() => toggle(!show)}
      >
        {showCartButton && (
          <CartIcon width={35}   
          style={{
            fill:
            filterProduct &&
            filterProduct.theme &&
            filterProduct.theme.description
              ? filterProduct.theme.description
              : "#4c8b58"
            }}
            
         />
        )}
        <div className={cartCountPosition}>
          <CartCount count={getCount()} />
        </div>
      </button>
      <CartModal onClose={() => toggle(!show)} show={show} cart={cartItems} />
      {!isHomepage && (
        <div className={brandingWrap}>
          <Logo className={logo} 
           style={{
            fill:
              filterProduct &&
              filterProduct.theme &&
              filterProduct.theme.description
                ? filterProduct.theme.description
                : "hsl(175, 41%, 18%)"
          }}/>
          <p className={quote}
           style={{
            color:
              filterProduct &&
              filterProduct.theme &&
              filterProduct.theme.description
                ? filterProduct.theme.description
                : "hsl(175, 41%, 18%)"
          }}
          >Your body is a Temple - Treat it like one</p>
        </div>
      )}
    </header>
  );
};

export default Header;
