import React from "react";
import styles from "./product-image.module.css";

const ProductImage = ({ color, src, alt }) => {
  return (
  <div
    className={styles.ProductImage}
    style={{ backgroundColor: color || "rgb(26 64 64 / 35%)" }}
  >
    <img
      src={src}
      alt={alt}
      className={styles.img}
    />
  </div>
)};

export default ProductImage;
